@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-snap-type: y mandatory;
}
.icon {
  display: block;
  font-size: xx-large;
}

.history-card {
  border-radius: 27px !important;
}

.thumbnail {
  border: 0px solid !important;
  height: 100%;
}

.thumbnail:hover {
  box-shadow: 0px 0px 18px black;
}

.saved-card {
  z-index: 2;
}

.saved-card:hover {
  box-shadow: 0px 0px 18px black;
}

.homepage-row > * {
  margin-bottom: 1rem;
}
.card {
  --bs-card-border-color: transparent !important;
  --bs-card-border-radius: 0px !important;
}
.card:hover {
  --bs-card-border-color: transparent !important;
  --bs-card-border-radius: 0px !important;
}
.flip-card {
  background-color: transparent;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  /* width: 100%; */
  aspect-ratio: 1;
}
.flip-card-inner {
  position: relative;
  /* width: 100%;
  height: 100%; */
  width: 144px;
  height: 144px;
  text-align: center;
  -webkit-transition: transform 0.8s;
  transition: transform 0.8s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
@media (max-width: 600px) {
  .flip-card-inner {
    -webkit-transition: none;
    transition: none;
    -webkit-transform-style: flat;
    transform-style: flat;
  }

  .flip-card:hover .flip-card-inner {
    -webkit-transform: none;
    transform: none;
  }
}
.game-card,
.game-card-back {
  border: 0px solid !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.game-card {
  background-color: white !important;
  color: black;
}

@media (max-width: 600px) {
  .game-card {
    background-color: white !important;
    color: white;
  }
}
.game-card-back {
  background-color: white !important;
  color: white;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  backdrop-filter: blur(1rem);
}

.card-back {
  display: grid !important;
}

.card-back > * {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.card-back .text {
  background-color: black;
  opacity: 0.85;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  border-radius: calc(0.375rem - 1px);
  padding: 0 0.5rem;
  font-weight: 600;
}

@media (max-width: 600px) {
  .game-card-back {
    display: none !important;
    background-color: white !important;
    color: white;
  }
  .card-back {
    display: none !important;
  }
  .card-back .text {
    display: none;
  }
}

.grid-autofit-breaks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(134px, 1fr));
  /* row-gap: 1rem; */
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*hide scroll bar for chrome, safari, opera*/
.reels::-webkit-scrollbar {
  display: none;
}
/*hide scrollbar for IE , edge, firefox*/
.reels {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: y mandatory; /* Enable vertical snap scrolling */
  overflow-y: scroll;
}
.aspect-video.active {
  height: 100vh; /* Set a fixed height for the active video container */
  overflow-y: scroll; /* Enable vertical scrolling for the active video */
}
.wrapper::-webkit-scrollbar {
  display: none;
}

.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
.current-video {
  border-color: #ff0000;
  border-width: 2px;
  border-style: solid;
}

.i {
  height: 46px;
  width: 46px;
  text-align: center;
  list-style: none;
  background-color: bisque;
  border-radius: 50%;
  cursor: pointer;
  padding: 12px;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px) {
  .corousal img {
    width: min-content;
  }
}
#root {
  overflow-x: hidden;
}
.hidden {
  display: none;
}
.small-screen {
  gap: 3rem;
}

.small-screen-col {
  width: 40%;
  height: 40%;
}
.no-scroll {
  overflow: hidden;
}
.slider .handle.handleNext {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  right: 0;
}
.slider .handle.active {
  cursor: pointer;
}
.slider .handle {
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  width: 4%;
  z-index: 20;
}
.slider .handle.handleNext .indicator-icon {
  transform-origin: 45% 50%;
}
.slider .handle > .indicator-icon {
  align-self: center;
  display: none;
  font-size: 2.5vw;
  height: auto;
  transition: transform 0.1s ease-out 0s;
}
[class*=' icon-'],
[class^='icon-'] {
  speak: none;
  font-family: nf-icon;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none;
  transform: translateZ(0);
}
.wrapper:hover .next-button,
.next-button:hover {
  opacity: 1;
}
.plus-icon-width {
  width: calc(100% + 40px);
}
.bg-gradient-to-r {
  opacity: 0.7;
}
.carousel-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  width: min-content;
}
.carousel-card {
  scroll-snap-align: start;
}
.carousel ul {
  width: 140px !important;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
.webkit {
  -webkit-overflow-scrolling: touch;
}
.carousel ul li {
  margin-right: 2px;
}
@media (min-width: 1024px) {
  .carousel {
    display: flex !important;
    overflow-y: hidden !important;
  }
}

@media (max-width: 1023px) {
  .carousel button {
    display: none !important;
  }
  .wrapper {
    overflow-x: auto !important;
    display: flex !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    /* width: 100% !important; */
  }
  .carousel ul {
    padding-left: 8px;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.multiplayer-modal-wrapper {
  z-index: 9999;
}

@media (max-width: 579px) {
  .modal-content {
    padding: 1rem !important;
    border-radius: 28px 28px 0 0 !important;
    height: 50% !important;
  }
  .multiplayer-modal-wrapper > * {
    padding: 0 !important;
  }
}
.modal {
  z-index: 99999999 !important;
}
